(function(window){
	var prizeoutSDK = function() {
		this.visible		= false;
		this.endpoint		= "http://localhost:8100/";
		this.listener		= null;
		this.dialog			= false; // Dialog not created
		this.iframe			= false; // Iframe not created
		this.dialogStyle	= "position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,0.3);";
	};
	prizeoutSDK.prototype.init = function(settings) {
		var scope = this;
		
		this.settings = settings;
		
		
		
		if (document.location.hostname=='localhost') {
			console.log("SDK settings", settings);
		}
		
		
		if (!this.settings.env) {
			this.settings.env = 'sandbox';
		}
		
		var i;
		var errors = [];
		if (!this.settings.user) {
			errors.push("Missing property: `user`");
			return false;
		}
		if (!this.settings.publisher) {
			errors.push("Missing property: `publisher`");
			return false;
		}
		
		
		var validate = function(propName, validationSettings) {
			for (i=0;i<validationSettings.length;i++) {
				switch (validationSettings[i][1]) {
					case "string":
						if (!settings[propName][validationSettings[i][0]]) {
							errors.push("Missing property: `"+propName+"."+validationSettings[i][0]+"`");
						}
						if (settings[propName][validationSettings[i][0]] && typeof settings[propName][validationSettings[i][0]]!="string") {
							errors.push("Wrong property type: `"+propName+"."+validationSettings[i][0]+"`. Expected: string, got "+typeof settings[propName][validationSettings[i][0]]);
						}
					break;
					case "year":
						if (!settings[propName][validationSettings[i][0]]) {
							errors.push("Missing property: `"+propName+"."+validationSettings[i][0]+"`");
						}
						/*if (settings[propName][validationSettings[i][0]] && typeof settings[propName][validationSettings[i][0]]!="number") {
							errors.push("Wrong property type: `"+propName+"."+validationSettings[i][0]+"`. Expected: number, got "+typeof settings[propName][validationSettings[i][0]]);
						}*/
						if (settings[propName][validationSettings[i][0]] && parseInt(settings[propName][validationSettings[i][0]])<1900 || parseInt(settings[propName][validationSettings[i][0]])>(new Date().getFullYear()-12)) {
							errors.push("Wrong property value: `"+propName+"."+validationSettings[i][0]+"` should be a valid 4 digit year.");
						}
					break;
					case "email":
						var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						if (!settings[propName][validationSettings[i][0]]) {
							errors.push("Missing property: `"+propName+"."+validationSettings[i][0]+"`");
						}
						if (settings[propName][validationSettings[i][0]] && typeof settings[propName][validationSettings[i][0]]!="string") {
							errors.push("Wrong property type: `"+propName+"."+validationSettings[i][0]+"`. Expected: string, got "+typeof settings[propName][validationSettings[i][0]]);
						}
						if (settings[propName][validationSettings[i][0]] && !emailRegex.test(String(settings[propName][validationSettings[i][0]]).toLowerCase())) {
							errors.push("Wrong property value: `"+propName+"."+validationSettings[i][0]+"` should be an email.");
						}
					break;
					case "number":
						if (!settings[propName][validationSettings[i][0]]) {
							errors.push("Missing property: `"+propName+"."+validationSettings[i][0]+"`");
						}
						if (settings[propName][validationSettings[i][0]] && typeof settings[propName][validationSettings[i][0]]!="number") {
							errors.push("Wrong property type: `"+propName+"."+validationSettings[i][0]+"`. Expected: number, got "+typeof settings[propName][validationSettings[i][0]]);
						}
					break;
					case "uuidv4":
						var uuidV4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
						if (!settings[propName][validationSettings[i][0]]) {
							errors.push("Missing property: `"+propName+"."+validationSettings[i][0]+"`");
						}
						if (settings[propName][validationSettings[i][0]] && typeof settings[propName][validationSettings[i][0]]!="string") {
							errors.push("Wrong property type: `"+propName+"."+validationSettings[i][0]+"`. Expected: string, got "+typeof settings[propName][validationSettings[i][0]]);
						}
						if (settings[propName][validationSettings[i][0]] && !uuidV4.test(String(settings[propName][validationSettings[i][0]]).toLowerCase())) {
							errors.push("Wrong property value: `"+propName+"."+validationSettings[i][0]+"` should be a UUIDv4.");
						}
					break;
					case "suuid":
						if (!settings[propName][validationSettings[i][0]]) {
							errors.push("Missing property: `"+propName+"."+validationSettings[i][0]+"`");
						}
						if (settings[propName][validationSettings[i][0]] && typeof settings[propName][validationSettings[i][0]]!="string") {
							errors.push("Wrong property type: `"+propName+"."+validationSettings[i][0]+"`. Expected: string, got "+typeof settings[propName][validationSettings[i][0]]);
						}
						if (settings[propName][validationSettings[i][0]] && typeof settings[propName][validationSettings[i][0]]=="string" && settings[propName][validationSettings[i][0]].length!=32) {
							errors.push("Wrong property value: `"+propName+"."+validationSettings[i][0]+"` should be a 32 chars string.");
						}
					break;
				}
			}
		}
		
		var userRequired = [['firstname', 'string']/*,['lastname', 'string']*/,['year_of_birth', 'year'],['email', 'email'],['user_id', 'string'],['country', 'string'],['region', 'string'],['balance', 'number']];
		var publisherRequired = [['name', 'string']/*,['logo', 'string']*/,['id', 'uuidv4'],['apikey', 'suuid']];
		
		// Validate
		validate("user", userRequired);
		validate("publisher", publisherRequired);
		
		if (errors.length > 0) {
			console.error("There are errors in your integration of the Prizeout SDK:");
		}
		for (i=0;i<errors.length;i++) {
			console.error(errors[i]);
		}
		
		if (errors.length > 0) {
			if (settings.callbacks && settings.callbacks.onInit) {
				settings.callbacks.onInit({});
			}
			return false;
		}
		
		this.encodedSettings	= '{}';
		
		//console.log("this.settings.dev", this.settings.dev);
		
		if (document.location.hostname=='localhost' && this.settings.dev===true) {
			this.env = "dev";
		} else {
			this.env = "prod";
		}
		
		console.log("this.env",this.env);
		
		switch (this.env) {
			case "dev":
				this.endpoint	= "http://localhost:8100/";
			break;
			default:
			case "prod":
				this.endpoint	= "https://"+(this.settings.env=='beta'?this.settings.env+'.':'')+"widget.prizeout.com/";
			break;
		}
		
		if (settings.callbacks && settings.callbacks.onInit) {
			settings.callbacks.onInit({});
		}
		scope.createDialog();
		
	};
	
	// Create a dialog
	prizeoutSDK.prototype.createDialog = function() {
		if (this.dialog && this.visible) {
			return this.dialog;
		}
		if (this.dialog && !this.visible) {
			this.dialog.setAttribute("style", this.dialogStyle+'display:table;');
			if (this.iframe) {
				// Reload the iframe
				this.iframe.setAttribute("src", this.endpoint+'?env='+this.settings.env+'&s='+this.encodedSettings+'&rnd='+Math.random());
			}
			this.visible	= true;
			return this.dialog;
		}
		this.dialog = document.createElement("div");
		this.dialog.setAttribute("style", this.dialogStyle+'display:table;');
		
		var row = document.createElement("div");
		row.setAttribute("style", "display:table-row;");
		this.dialog.appendChild(row);
		
		var cell = document.createElement("div");
		cell.setAttribute("style", "display:table-cell;text-align:center;vertical-align:middle;");
		row.appendChild(cell);
		
		this.createIframe(cell);
		
		
		document.body.appendChild(this.dialog);
		
		this.visible	= true;
	};
	
	// Create an iframe and connect to it via the SDKs
	prizeoutSDK.prototype.createIframe = function(parent) {
		var scope = this;
		if (this.iframe) {
			return this.iframe;
		}
		
		this.iframe = document.createElement("iframe");
		this.iframe.setAttribute("src", this.endpoint+'?env='+this.settings.env+'&s='+scope.encodedSettings);
		this.iframe.setAttribute("style", "width:100%;max-width:400px;height:100%;max-height:700px;border:0;border-radius:3px;box-shadow:0 0 25px rgba(0,0,0,0.6);");
		
		if (this.listener) {
			window.removeEventListener('message', this.listener);
		}
		this.listener = window.addEventListener('message', function(e) {
			scope.handleIncomingMessage(e);
		});
		this.iframe.onload = function() {
			scope.iframeLoaded = true;
			console.log("iFrame loaded");
			// handshake with the iframe
			scope.handshake();
		};
		this.iframeParent	= parent;
		this.iframeParent.appendChild(this.iframe);
	};
	
	// Identify to the iframe
	prizeoutSDK.prototype.handshake = function() {
		this.send("settings", this.settings);
		this.settingsSent	= true;
	};
	
	
	
	// Hide the iframe
	prizeoutSDK.prototype.close = function(response) {
		console.log("close", response);
		this.dialog.setAttribute("style", this.dialogStyle+'display:none;');
		console.log("this.dialog", this.dialog);
		this.visible		= false;
		this.iframeLoaded 	= false;
		this.settingsSent	= false;
		if (this.settings.callbacks && this.settings.callbacks.onClose) {
			this.settings.callbacks.onClose(response);
		}
	};
	
	// Cashout Failed
	prizeoutSDK.prototype.onCashoutFail = function(response) {
		if (this.settings.callbacks && this.settings.callbacks.onCashoutFail) {
			this.settings.callbacks.onCashoutFail(response);
		}
	};
	
	// Cashout Completed
	prizeoutSDK.prototype.onCashoutSuccess = function(response) {
		if (this.settings.callbacks && this.settings.callbacks.onCashoutSuccess) {
			this.settings.callbacks.onCashoutSuccess(response);
		}
	};
	
	// Handle incoming messages from the iframe
	prizeoutSDK.prototype.handleIncomingMessage = function(event) {
		var scope = this;
		try {
			var payload = JSON.parse(event.data);
		} catch (e) {
			var payload = event.data;
		}
		
		var rawType		= payload.type;
		var dataPayload	= payload.payload;
		
		switch (rawType) {
			case "hello":
				// Send back the settings
				if (this.iframeLoaded) {
					this.send("settings", this.settings);
					this.settingsSent	= true;
				} // Otherwise wait until it's loaded
			break;
			case "close":
				this.close(dataPayload);
			break;
			case "cashoutFail":
				this.onCashoutFail(dataPayload);
			break;
			case "cashoutSuccess":
				this.onCashoutSuccess(dataPayload);
			break;
		}
	};
	// Send messages to the iframe
	prizeoutSDK.prototype.send = function(type, payload) {
		this.iframe.contentWindow.postMessage({
			type:		type,
			payload:	payload && typeof payload === 'object' && payload.constructor === Object?JSON.stringify(payload):payload
		}, "*"/*this.endpoint*/);
	};
	window.prizeoutSDK	= new prizeoutSDK();

})(window);