// disable alphabet to enter in input field using class name numberFilter
$(function() {
    $(document.body).on('keydown', '.numberFilter', function(e){
        -1!==$.inArray(e.keyCode,[46,8,9,27,13,110,190])||/65|67|86|88/.test(e.keyCode)&&(!0===e.ctrlKey||!0===e.metaKey)||35<=e.keyCode&&40>=e.keyCode||(e.shiftKey||48>e.keyCode||57<e.keyCode)&&(96>e.keyCode||105<e.keyCode)&&e.preventDefault()
        if((e.keyCode == 69) || (e.keyCode == 101)){
            e.preventDefault();
        }
    });

    $(document.body).on('focus','input,textarea', function(){
        $(this).addClass('focus');
    })
    $(document.body).on('blur','input,textarea',function(){
        $(this).removeClass('focus');
    })

    $(document.body).on("keypress",".integer",function(e) {
        // between 0 and 9
        // if (e.which < 48 || e.which > 57) {
        //     return(false);  // stop processing
        // }
        if (e.which < 48 || e.which > 57 ) {
          	if(e.which == 8){
            	 return(true);  // stop processing
            }else{
            	return(false);
            }
         }else{
         	return(true);
         }
    });

    $(document.body).on("keypress",".decimal",function(e) {
        // 46 is a decimal
        if (e.which == 46 && this.value.indexOf(".") != -1) {
            return(false);   // only one decimal allowed
        }
    });
})
